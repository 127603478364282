<script setup>
import { computed } from "vue"

const props = defineProps({
  color: {
    type: String,
  },
  disabled: {
    type: Boolean,
  },
  errorMessages: {
    type: [Array, String],
    default: () => {
      return []
    },
  },
  falseValue: {},
  hideDetails: {
    type: [Boolean, String],
  },
  hint: {
    type: String,
  },
  label: {
    type: String,
  },
  persistentHint: {
    type: Boolean,
    default: false,
  },
  trueValue: {},
  modelValue: {},
})

const emit = defineEmits(["change", "click", "update:modelValue"])

const localValue = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit("update:modelValue", v)
  },
})
</script>

<template>
  <v-checkbox
    v-model="localValue"
    :disabled="disabled"
    :error-messages="errorMessages"
    :false-value="falseValue"
    :hide-details="hideDetails"
    :hint="hint"
    :label="label"
    :persistent-hint="persistentHint"
    :true-value="trueValue"
    @update:model-value="emit('change', $event)"
    @click="emit('click', $event)"
  >
    <template v-if="typeof label === 'undefined'" #label>
      <slot name="label" />
    </template>
  </v-checkbox>
</template>

<style lang="scss">
.uc-checkbox {
  display: flex;
  align-items: center;
  label {
    padding-left: 10px;
  }
}
</style>
